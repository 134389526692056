
import { defineComponent, PropType } from 'vue';
import type { ValidationState } from '@white-label-types/vue-plugins';

import { getAppVariable } from '@white-label-helper/get-app-variable';
import {
  US_PHONE_DEFAULT_FORMAT,
  PHONE_DEFAULT_FORMAT,
} from '@white-label-configuration/constants';

// @ts-ignore
import VuePhoneNumberInput from 'vue-phone-number-input';

interface VuelidateState extends ValidationState {
  onlyNumbers?: boolean;
  minLength?: boolean;
  maxLength?: boolean;
  required?: boolean;
}

type PhoneFieldEvent = {
  /**
   * Dialing code
   * @example "421"
   */
  countryCallingCode: string;
  /**
   * ISO
   * @example "SK"
   */
  countryCode: string;
  /** @example "+421987609783" */
  e164: string;
  /** @example "+421 987 609 783" */
  formatInternational: string;
  /** @example "0987 609 783" */
  formatNational: string;
  /** @example "+421987609783" */
  formattedNumber: string;
  isValid: boolean;
  /** @example "987609783" */
  nationalNumber: string;
  /** @example "0987609783" */
  phoneNumber: string;
  type: undefined;
  /** @example "tel:+421987609873" */
  uri: string;
};

export default defineComponent({
  name: 'PhoneField',

  components: {
    VuePhoneNumberInput,
  },

  props: {
    value: {
      type: Object as PropType<{
        phone_number?: string;
        formatted_number?: string;
        country_code?: string;
        country_iso_code?: string;
      }>,
      required: true,
      default: () => ({
        phoneNumber: '',
        formattedNumber: '',
        countryCode: '',
        countryIsoCode: '',
      }),
    },

    error: {
      type: Object as PropType<VuelidateState | {}>,
      required: false,
      default: () => ({}),
    },

    customErrorMessages: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },

    label: {
      type: String,
      default: '',
    },

    addRequiredStar: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      placeholder: '',
    };
  },

  computed: {
    /**
     * Get the airport country code from the partner config
     * @returns Airport country code
     */
    airportCountryCode(): string {
      try {
        return getAppVariable('poi.country.code');
      } catch (err) {
        return 'US';
      }
    },

    isErrorExist(): boolean {
      return '$error' in this.error && this.error.$error;
    },

    errorMessage(): string {
      if (this.customErrorMessage) {
        return this.customErrorMessage;
      }
      if ('required' in this.error && !this.error.required) {
        return `${this.$t(
          'UI.formErrors.pleaseEnter'
        )} ${this.label.toLowerCase()}.`;
      }

      return `${this.$t(
        'UI.formErrors.pleaseEnterValid'
      )} ${this.label.toLowerCase()}`;
    },

    customErrorMessage(): string {
      const errorState: string = this.getErrorState();
      return this.getErrorMessage(errorState);
    },
  },

  mounted() {
    //set phoneValid as true allows to wait until get value.phone_number and check if this is valid
    this.$parent.phoneValid = true;
    this.customiseVuePhoneInputLibrary();

    if (this.airportCountryCode === 'US') {
      this.placeholder = US_PHONE_DEFAULT_FORMAT;
    } else {
      this.placeholder = PHONE_DEFAULT_FORMAT;
    }
  },

  beforeDestroy() {
    const { phoneField } = this.$refs;
    if (phoneField instanceof HTMLDivElement) {
      const inputTel = phoneField.querySelector('.input-tel input');
      const countrySelector = phoneField.querySelector(
        '.country-selector input'
      );

      if (inputTel instanceof HTMLElement) {
        inputTel.removeEventListener('focus', this.callbackFocus);
        inputTel.removeEventListener('blur', this.callbackBlur);
      }

      if (countrySelector instanceof HTMLElement) {
        countrySelector.removeEventListener('focus', this.callbackFocus);
        countrySelector.removeEventListener('blur', this.callbackBlur);
      }
    }
  },

  methods: {
    /**
     * This had been done because:
     * 1. Requirement is need to have two different items in input for phone (country select, phone number)
     *    and each needed own focus/hover state.
     * 2. Library which had been used doesn't give easy access to event to focus/blur state
     *    and to setup custom attributes.
     *    That is why had been decided to write custom one.
     */
    customiseVuePhoneInputLibrary() {
      const { phoneField } = this.$refs;
      if (phoneField instanceof HTMLDivElement) {
        const inputTel = phoneField.querySelector('.input-tel input');
        const countrySelector = phoneField.querySelector(
          '.country-selector input'
        );

        if (inputTel instanceof HTMLElement) {
          inputTel.addEventListener('focus', this.callbackFocus);
          inputTel.addEventListener('blur', this.callbackBlur);
          inputTel.setAttribute('data-testid', 'phone-number-input');
        }

        if (countrySelector instanceof HTMLElement) {
          countrySelector.addEventListener('focus', this.callbackFocus);
          countrySelector.addEventListener('blur', this.callbackBlur);
        }
      }
    },

    callbackFocus() {
      const { phoneField } = this.$refs;
      if (phoneField instanceof HTMLDivElement) {
        phoneField.classList.add('input-focus');
      }
    },

    callbackBlur() {
      const { phoneField } = this.$refs;
      if (phoneField instanceof HTMLDivElement) {
        phoneField.classList.remove('input-focus');
      }
    },

    updateInput(e: PhoneFieldEvent) {
      this.$emit('input', {
        phone_number: e.phoneNumber ?? '',
        formatted_number: e.formattedNumber ?? '',
        country_code: e.phoneNumber ? e.countryCallingCode : '',
        country_iso_code: e.countryCode,
      });

      if (this.$parent) {
        // @ts-ignore - TS doesn't recognize phoneValid could be on the parent
        this.$parent.phoneValid = e.isValid;
      }

      this.placeholder =
        e.countryCode === 'US' ? US_PHONE_DEFAULT_FORMAT : PHONE_DEFAULT_FORMAT;
    },

    onPressEnter(e: KeyboardEvent) {
      // When pressing the return key while the country selector is open,
      // prevent the form from submitting.
      const { phoneField } = this.$refs;

      if (phoneField instanceof HTMLDivElement) {
        const countrySelectorOpen =
          phoneField.querySelector('.has-list-open') !== null;

        if (countrySelectorOpen) {
          e.preventDefault();
        }
      }
    },

    /**
     * Looking through the vuelidate error object to find the validation method that has been flags,
     * we then use the key to load the property of the same name from the list of customer error messages.
     * If no message matches, use the default one.
     * @returns The property key that failed teh validation
     */
    getErrorState(): string {
      if (this.isErrorExist) {
        const {
          onlyNumbers = true,
          minLength = true,
          maxLength = true,
          required = true,
        } = this.error as VuelidateState;

        if (!onlyNumbers) {
          return 'onlyNumbers';
        }

        if (!minLength) {
          return 'minLength';
        }

        if (!maxLength) {
          return 'maxLength';
        }

        if (!required) {
          return 'required';
        }
      }

      return 'phoneInvalid';
    },

    getErrorMessage(errorName: string): string {
      return this.customErrorMessages[errorName];
    },
  },
});
