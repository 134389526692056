export const DOMAINS_NAMES = {
  ACCOUNT: 'ACCOUNT',
  CHECKOUT: 'CHECKOUT',
  ECOMMERCE: 'ECOMMERCE',
} as const;

export const DOMAINS = {
  ACCOUNT: process.env.NUXT_ENV_ACCOUNT_URL,
  CHECKOUT: process.env.NUXT_ENV_CHECKOUT_URL,
  ECOMMERCE: process.env.NUXT_ENV_ECOMMERCE_URL,
} as const;

export const ACCOUNT_URLS = {
  HOME: '/',
  MANAGE_BOOKING: 'manage-booking/',
  SEARCH_BOOKING: 'search-booking/',
  LOGIN: 'login/'
} as const

export const ECOMMERCE_URLS = {
  HOME: '/',
  MANAGE_BOOKING: 'manage-booking/',
  MODIFY: '/manage-booking/modify',
} as const

export const CHECKOUT_URLS = {
  BOOKING: 'checkout/',
  BOOKING_LOUNGES: 'checkout-lounges/',
  MODIFY: 'modify-checkout/',
} as const

export const MANAGE_BOOKING_URLS = {
  BOOKING_SEARCH: '/search/',
  BOOKING_SEARCH_LOUNGES: '/search-lounges/',
  BOOKING_RECEIPT: '/receipt/',
  SELECT_EXTRAS: '/select-extras/',
  SELECT_EXTRAS_LOUNGES: '/select-extras/lounges',
  SELECT_EXTRAS_FASTTRACK: '/select-extras/fasttrack',
  MODIFY_SEARCH: '/manage-booking/modify/',
  MODIFY_RECEIPT: '/manage-booking/receipt/',
  MANAGE_BOOKING: '/manage-booking/',
} as const

export const PRODUCT = {
  DEFAULT: process.env.NUXT_ENV_DEFAULT_HERO_PRODUCT,
} as const
