import { DefaultPrivacyLevel } from '@datadog/browser-core';
import { datadogRum } from '@datadog/browser-rum';
interface WindowWithDatadog extends Window {
  getDatadogSessionId?: () => string | undefined;
}

export default () => {
  const applicationId = process.env['NUXT_ENV_DATADOG_RUM_APPLICATION_ID'];
  const clientToken = process.env['NUXT_ENV_DATADOG_RUM_CLIENT_TOKEN'];

  if (applicationId === undefined) {
    throw new Error('NUXT_ENV_DATADOG_RUM_APPLICATION_ID environment variable is required for data dog');
  }

  if (clientToken === undefined) {
    throw new Error('NUXT_ENV_DATADOG_RUM_CLIENT_TOKEN environment variable is required for data dog');
  }

  const defaultPrivacyLevel = process.env['NUXT_ENV_DATADOG_RUM_DEFAULT_PRIVACY_LEVEL'] as DefaultPrivacyLevel | undefined;

  const env = process.env['NUXT_ENV_DATADOG_RUM_ENVIRONMENT'];
  const service = process.env['NUXT_ENV_DATADOG_RUM_SERVICE'];

  const sessionSampleRate = process.env['NUXT_ENV_DATADOG_RUM_SESSION_SAMPLE_RATE']
    ? Number(process.env['NUXT_ENV_DATADOG_RUM_SESSION_SAMPLE_RATE'])
    : undefined;

  const sessionReplaySampleRate = process.env['NUXT_ENV_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE']
    ? Number(process.env['NUXT_ENV_DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE'])
    : undefined;

  const site = process.env['NUXT_ENV_DATADOG_RUM_SITE'];

  const trackLongTasks = process.env['NUXT_ENV_DATADOG_RUM_TRACK_LONG_TASKS']
    ? Boolean(process.env['NUXT_ENV_DATADOG_RUM_TRACK_LONG_TASKS'])
    : undefined;

  const trackResources = process.env['NUXT_ENV_DATADOG_RUM_TRACK_RESOURCES']
    ? Boolean(process.env['NUXT_ENV_DATADOG_RUM_TRACK_RESOURCES'])
    : undefined;

  const trackUserInteractions = process.env['NUXT_ENV_DATADOG_RUM_TRACK_USER_INTERACTIONS']
    ? Boolean(process.env['NUXT_ENV_DATADOG_RUM_TRACK_USER_INTERACTIONS'])
    : undefined;

  const trackSessionAcrossSubdomains = true;
  const allowedTracingUrls = [(url: string) => {
    return !url.includes('appspot.com')
      && url.includes('cavu-tech.com')
  }];

  const enableExperimentalFeatures = ['feature_flags']

  datadogRum.init({
    applicationId,
    clientToken,
    defaultPrivacyLevel,
    env,
    service,
    sessionSampleRate,
    sessionReplaySampleRate,
    site,
    trackLongTasks,
    trackResources,
    trackUserInteractions,
    trackSessionAcrossSubdomains,
    allowedTracingUrls,
    enableExperimentalFeatures
  });

  datadogRum.startSessionReplayRecording();

  if (typeof window !== 'undefined') {
    (window as WindowWithDatadog).getDatadogSessionId = () => {
      return datadogRum.getInternalContext()?.session_id;
    }
  }
};
