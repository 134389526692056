import { httpBooking } from '@white-label-configuration/http';
import {
  parseManageBookingPiiData,
  parseManageBookingState,
} from '@white-label-helper/data-parsers-manage-booking';

// Types
import type { User } from '@white-label-types/parking-shared';
import { BookingGuestDetailsData } from '@white-label-types/account-booking';
import { datadogRum } from '@datadog/browser-rum';


// @ts-expect-error property
httpBooking.onRequest((config) => {
  const session_id = datadogRum.getInternalContext()?.session_id || null;
  config.headers['X-Datadog-Session-ID'] = session_id;
});

interface UpdateBookingDetailsBody {
  order_reference: string;
  user: User;
}

interface ResendConfirmation {
  email: string;
  entry_date: string;
}

interface ResendConfirmationType extends ResendConfirmation {
  product_type: 'lounges' | 'parking';
}

interface SearchBookingBody {
  email: string;
  reference_id: string | (string | null)[];
}

interface CancelBookingBody {
  order_item_reference: string;
  reason: string;
  meta: Record<string, unknown>;
}

interface BaggageDetailsBody {
  baggage_count: string;
  has_oversized_baggage: boolean;
  declaration_required: boolean;
  declaration_comment: string;
}

interface TempStoragePayload {
  name: string;
  prefix: string;
  content_type: string;
}

export const updateBookingDetails = (body: UpdateBookingDetailsBody) =>
  httpBooking.put('pii-data', { body });

export const getManageBookingState = (manageBookingToken: string) =>
  httpBooking
    .get('account/booking/management', {
      headers: { 'Management-Token': manageBookingToken },
    })
    .then((res) => parseManageBookingState(res.body.data));

export const resendConfirmation = (
  body: ResendConfirmation | ResendConfirmationType
) =>
  httpBooking
    .post('booking/receipt/resend', { body })
    .then((data) => data.body);

export const searchBooking = (body: SearchBookingBody) =>
  httpBooking
    .post('account/booking/management/token', { body })
    .then((res) => res.body.data);

export const cancellationCancelBooking = (
  manageBookingToken: string,
  body: CancelBookingBody
) =>
  httpBooking.patch('booking/order/item', {
    headers: {
      'Management-Token': manageBookingToken,
    },
    body,
  });

export const getBookingBaggageDetails = (
  bearerToken: string,
  manageBookingToken: string,
  orderItemId: string
) =>
  httpBooking
    .get(`account/pii-questions-data/${orderItemId}/baggage-config`, {
      headers: {
        Authorization: bearerToken,
        'Management-Token': manageBookingToken,
      },
      query: {
        vault: 'booking-post-sales',
      },
    })
    .then((res) => res.body.data)
    .catch((err) => console.log(err));

export const cancellationEstimateBooking = (
  manageBookingToken: string,
  itemIds?: string[]
) =>
  httpBooking
    .get('/booking/cancel-receipt/estimate', {
      headers: { 'Management-Token': manageBookingToken },
      ...(itemIds && { query: { items: itemIds } }),
    })
    .then((res) => parseManageBookingState(res.body.data));

export const cancellationReceiptBooking = (manageBookingToken: string) =>
  httpBooking
    .get('/booking/cancel-receipt', {
      headers: { 'Management-Token': manageBookingToken },
    })
    .then((res) => res.body.data);

export const setBookingBaggageDetails = (
  bearerToken: string,
  manageBookingToken: string,
  orderItemId: string,
  body: BaggageDetailsBody
) =>
  httpBooking
    .post(`account/pii-questions-data/${orderItemId}/baggage-config`, {
      body: {
        ...body,
        vault: 'booking-post-sales',
      },
      headers: {
        Authorization: bearerToken,
        'Management-Token': manageBookingToken
      },
    })
    .then((res) => res.body.data)
    .catch((err) => console.log(err));

export const getBookingItemPiiData = (
  bearerToken: string,
  orderItemId: string
) =>
  httpBooking
    .get(`account/pii-questions-data/${orderItemId}?vault=booking-post-sales`, {
      headers: {
        Authorization: bearerToken,
      },
    })
    .then((res) => parseManageBookingPiiData(res.body.data))
    .catch((err) => console.log(err));

export const postGuestDetails = (
  bearerToken: string,
  orderItemId: string,
  payload: Partial<BookingGuestDetailsData>
) =>
  httpBooking
    .post('account/pii-questions-data/guest', {
      body: {
        order_item_id: orderItemId,
        vault: 'booking-post-sales',
        data: payload,
      },
      headers: {
        Authorization: bearerToken,
      },
    })
    .then((res) => res.body.data)
    .catch((err) => console.log(err));

export const postTempStorageUrl = (
  bearerToken: string,
  payload: TempStoragePayload
) =>
  httpBooking
    .post('account/pii-questions-data/signed-storage-url', {
      body: payload,
      headers: {
        Authorization: bearerToken,
      },
    })
    .then((res) => res.body.data);

export const getShareObjectUrl = (bearerToken: string, payload) =>
  httpBooking
    .post('account/pii-questions-data/share-object-url', {
      body: payload,
      headers: {
        Authorization: bearerToken,
      },
    })
    .then((res) => res.body.data);

export const postQuestionnaire = (bearerToken: string, payload) =>
  httpBooking
    .post('account/pii-questions-data', {
      body: payload,
      headers: {
        Authorization: bearerToken,
      },
    })
    .then((res) => res.body.data);
