
import Vue, { defineComponent, PropType } from 'vue';
import {
  DATE_TIME_FORMATS,
  format,
  parseISO,
} from '@white-label-helper/date-utilities';

import Backdrop from '../back-drop/back-drop.vue';
import DatePicker from '../datepicker/datepicker.vue';
import TextField from '../text-field/text-field.vue';

interface ControlDateProps {
  timeZone: string;
  minDate: Date;
  maxDate: Date;
}

interface CustomErrorMessages {
  [key: string]: string;
}

export default defineComponent({
  name: 'TextFieldDate',

  components: {
    Backdrop,
    DatePicker,
    TextField,
  },

  props: {
    prependIconClass: {
      type: String,
      default: '',
    },

    prependIcon: {
      type: Object,
      default: null,
    },

    value: {
      type: String,
      required: true,
    },

    error: {
      type: Object as PropType<Vue['error']>,
      default: () => ({}),
    },

    label: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    customErrorMessages: {
      type: Object as PropType<CustomErrorMessages>,
      default: () => ({}),
    },

    controlDateProps: {
      type: Object as PropType<ControlDateProps>,
      required: true,
    },

    dateFormat: {
      type: String as PropType<keyof typeof DATE_TIME_FORMATS>,
      default: DATE_TIME_FORMATS.month_day_year,
    },

    addRequiredStar: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    selectorType: {
      type: String,
      default: 'static',
    },

    monthToShow: {
      type: Number,
      default: null,
    },

    useModelBinding: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dateDropdownOpened: false,
    };
  },

  computed: {
    formattedDate(): string {
      let date = '';

      if (this.localValue) {
        date = format(parseISO(this.localValue), this.dateFormat);
      }
      return date;
    },
    hasBackdrop: {
      get(): boolean {
        return (
          (this.isMobileResolution || this.isTabletResolution) &&
          this.dateDropdownOpened
        );
      },
      set() {
        this.closeDateDropdown();
      },
    },

    localValue: {
      get(): string {
        return this.value;
      },
      set(val: string) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    closeDateDropdown() {
      this.dateDropdownOpened = false;
    },

    onDateChange(date: string) {
      this.localValue = date;
      this.closeDateDropdown();
    },

    inputHandler(val: string) {
      this.$emit('input', val);
    },
  },
});
