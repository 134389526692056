import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _01e399b2 = () => interopDefault(import('../src/pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _2713bfca = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _e332ede6 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _247b3e30 = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _51c46822 = () => interopDefault(import('../src/pages/receipt.vue' /* webpackChunkName: "pages/receipt" */))
const _65012141 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _6b0be690 = () => interopDefault(import('../src/pages/search-booking/index.vue' /* webpackChunkName: "pages/search-booking/index" */))
const _1e032b0e = () => interopDefault(import('../src/pages/select-extras/index.vue' /* webpackChunkName: "pages/select-extras/index" */))
const _59a95d66 = () => interopDefault(import('../src/pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _33f93a72 = () => interopDefault(import('../src/pages/unsupported-browser.vue' /* webpackChunkName: "pages/unsupported-browser" */))
const _43e63112 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _5cb6ac0f = () => interopDefault(import('../src/pages/manage-booking/modify.vue' /* webpackChunkName: "pages/manage-booking/modify" */))
const _1200da9a = () => interopDefault(import('../src/pages/manage-booking/receipt.vue' /* webpackChunkName: "pages/manage-booking/receipt" */))
const _08ab7993 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _74c7666e = () => interopDefault(import('../src/pages/select-extras/fasttrack/index.vue' /* webpackChunkName: "pages/select-extras/fasttrack/index" */))
const _57d542f8 = () => interopDefault(import('../src/pages/select-extras/lounges/index.vue' /* webpackChunkName: "pages/select-extras/lounges/index" */))
const _4b354d78 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _05f9e149 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cookie-policy",
    component: _01e399b2,
    name: "cookie-policy"
  }, {
    path: "/maintenance",
    component: _2713bfca,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _e332ede6,
    name: "manage-booking"
  }, {
    path: "/privacy-policy",
    component: _247b3e30,
    name: "privacy-policy"
  }, {
    path: "/receipt",
    component: _51c46822,
    name: "receipt"
  }, {
    path: "/search",
    component: _65012141,
    name: "search"
  }, {
    path: "/search-booking",
    component: _6b0be690,
    name: "search-booking"
  }, {
    path: "/select-extras",
    component: _1e032b0e,
    name: "select-extras"
  }, {
    path: "/terms-and-conditions",
    component: _59a95d66,
    name: "terms-and-conditions"
  }, {
    path: "/unsupported-browser",
    component: _33f93a72,
    name: "unsupported-browser"
  }, {
    path: "/manage-booking/cancel",
    component: _43e63112,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/modify",
    component: _5cb6ac0f,
    name: "manage-booking-modify"
  }, {
    path: "/manage-booking/receipt",
    component: _1200da9a,
    name: "manage-booking-receipt"
  }, {
    path: "/manage-booking/update-details",
    component: _08ab7993,
    name: "manage-booking-update-details"
  }, {
    path: "/select-extras/fasttrack",
    component: _74c7666e,
    name: "select-extras-fasttrack"
  }, {
    path: "/select-extras/lounges",
    component: _57d542f8,
    name: "select-extras-lounges"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _4b354d78,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _05f9e149,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
