
import { defineComponent, PropType } from 'vue';
import type VueI18n from 'vue-i18n';
import { LOCAL_ERROR_SHOW_TIME } from '@white-label-configuration/constants';
type CustomErrorMessages = {
  [key: string]: string;
};

export default defineComponent({
  props: {
    prependIconClass: {
      default: '',
      type: String,
    },
    customClasses: {
      default: '',
      type: String,
    },
    prependIcon: {
      default: '',
      type: String,
    },
    appendIcon: {
      default: '',
      type: String,
    },
    appendIconClass: {
      default: '',
      type: String,
    },
    maxLength: {
      default: 0,
      type: Number,
    },
    onlyNumbers: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    type: {
      type: String,
      default: 'text',
    },

    label: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    error: {
      type: Object as PropType<Vue['error']>,
      default: () => ({}),
    },
    addRequiredStar: {
      type: Boolean,
      default: false,
    },
    inputUpperCase: {
      type: Boolean,
      default: false,
    },
    inputLowerCase: {
      type: Boolean,
      default: false,
    },
    noPaste: {
      type: Boolean,
      default: false,
    },
    displayErrorMessage: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    customErrorMessages: {
      type: Object as PropType<CustomErrorMessages>,
      default: () => ({}),
    },
    testId: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localError: '' as VueI18n.TranslateResult | string,
      localErrorTimeout: 0,
    };
  },

  computed: {
    slotExists(): boolean {
      return !!this.$slots?.button;
    },

    isErrorExists(): boolean {
      return (this.error && this.error.$error) || !!this.localError;
    },

    errorMessage(): VueI18n.TranslateResult | string {
      if (this.localError) {
        return this.localError;
      }
      if (this.customErrorMessage) {
        return this.customErrorMessage;
      }
      if (!this.error.required) {
        return `${this.$t(
          'UI.formErrors.pleaseEnter'
        )} ${this.label.toLowerCase()}.`;
      }
      if (this.type === 'password') {
        return "Your password doesn't meet requirements";
      }

      return `${this.$t(
        'UI.formErrors.pleaseEnterValid'
      )} ${this.label.toLowerCase()}`;
    },

    customErrorMessage(): string {
      const errorState: string = this.getErrorState();
      return this.getErrorMessage(errorState);
    },
  },

  methods: {
    onClick(): void {
      if (this.readonly) {
        this.$emit('clickReadonly');
      }
    },

    transformUpperCase(value: string): string {
      if (this.inputUpperCase) {
        return value.toUpperCase();
      }
      return value;
    },

    transformLowerCase(value: string): string {
      if (this.inputLowerCase) {
        return value.toLowerCase();
      }
      return value;
    },

    transformMaxLength(value: string): string {
      if (
        this.maxLength &&
        typeof this.maxLength === 'number' &&
        value.length > this.maxLength
      ) {
        this.setLocalErrorState(
          this.$t('UI.form.global.maxLength', { maxLength: this.maxLength })
        );

        return this.value;
      }
      this.localError = '';
      return value;
    },

    transformOnlyNumbers(value: string): string {
      if (this.onlyNumbers) {
        return value.replace(/\D+/g, '');
      }
      return value;
    },

    onInput(e: Event): void {
      let value = (e.target as HTMLInputElement).value;

      // transform value
      value = this.transformUpperCase(value);
      value = this.transformLowerCase(value);
      value = this.transformMaxLength(value);
      value = this.transformOnlyNumbers(value);

      this.$emit('input', value);
      this.$forceUpdate();
    },

    onFocus(): void {
      this.$emit('focus');
    },

    onBlur(): void {
      this.$emit('blur');
    },

    onKeypress(e: KeyboardEvent): void {
      this.$emit('keypress', e);
    },

    onPaste(e: ClipboardEvent): void {
      if (this.noPaste) {
        e.preventDefault();
        this.$emit('paste');
      }
    },

    setLocalErrorState(
      newLocalErrorState: VueI18n.TranslateResult | string
    ): void {
      this.localError = newLocalErrorState;

      if (this.localErrorTimeout) {
        clearTimeout(this.localErrorTimeout);
      }
      this.localErrorTimeout = window.setTimeout(() => {
        this.localError = '';
      }, LOCAL_ERROR_SHOW_TIME);
    },

    getErrorState(): string {
      if (!this.error.$error) {
        return '';
      }
      const validationFields = Object.entries(this.error).filter(
        (i) => i[0][0] !== '$'
      );
      const foundField = validationFields.find(([, value]) => !value);
      console.log('validation state', foundField ? foundField[0] : '');
      return foundField ? foundField[0] : '';
    },

    getErrorMessage(errorName: string): string {
      if (!errorName || !this.customErrorMessages) {
        return '';
      }
      return this.customErrorMessages[errorName];
    },
  },
});
