/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { UserDetailsResponse, UserPayload, EmailVerifyPayload, ChangePasswordResponse } from '@white-label-helper/api-parkings-user';
import { emailVerification, userDetails } from '@white-label-helper/api-parkings-user';
import { RootState } from '@white-label-types/stores';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';

/**
 * @file File for keeping user data in the store to be used in the app
 */

type UserInfoContext = ActionContext<State, RootState>;

type UserDetails = {
  firstName: string,
  lastName: string,
  phoneNumber: State['phoneDetails'],
  vehicleDetails: State['vehicleDetails'],
  postalCode: string
}

type VehiclePayload = {
  registrationNumber: string
  make: string | null
  model: string | null
  colour: string | null
}

export type State = {
  email: string
  firstName: string
  lastName: string

  phoneDetails: {
    phoneNumber: string
    phoneNumberWithDialingCode: string
    dialingCode: string
    isoCode: string
  },

  vehicleDetails: {
    registrationNumber: string
    make: string
    model: string
    colour: string
  }

  postalCode: string,
  marketingChannels: {
    sms?: boolean,
    email?: boolean
  }
}

const state = (): State => ({
  email: '',
  firstName: '',
  lastName: '',

  phoneDetails: {
    phoneNumber: '',
    phoneNumberWithDialingCode: '',
    dialingCode: '',
    isoCode: ''
  },

  vehicleDetails: {
    registrationNumber: '',
    make: '',
    model: '',
    colour: '',
  },

  postalCode: '',
  marketingChannels: {}
});

type Getters = {
  getEmail(state: State): State['email']
  getFirstName(state: State): State['firstName']
  getLastName(state: State): State['lastName']
  getPhoneNumbers(state: State): State['phoneDetails']
  getVehicleDetails(state: State): State['vehicleDetails']
  getPostalCode(state: State): State['postalCode']
  getAllDetails(state: State): UserDetails,
  getMarketingPreferences(state: State): State['marketingChannels'] | null
}

const getters: Getters = {
  /**
   * Get the user's email address
   * @param state - the state object for userInfo
   */
  getEmail: (state: State) => state.email,

  /**
   * Get the user's first name
   * @param state - the state object for userInfo
   */
  getFirstName: (state: State) => state.firstName,

  /**
   * Get the user's last name
   * @param state - the state object for userInfo
   */
  getLastName: (state: State) => state.lastName,

  /**
   * Gets the user phone number
   * @param state - the state object for userInfo
   */
  getPhoneNumbers: (state: State) => {
    return state.phoneDetails
  },

  /**
   * Gets the details of the user's vehicles
   * @param state - the state object for userInfo
   */
  getVehicleDetails: (state: State) => {
    return state.vehicleDetails
  },

  /**
   * Gets the user's postcode or postal code
   * @param state - the state object for userInfo
   */
  getPostalCode: (state: State) => state.postalCode,

  /**
   * Return what if any of the marketing channels we have that the customer has subscribed too
   * @param state - The state object of the userinfo
   */
  getMarketingPreferences: (state: State) => {
    if (state.marketingChannels && Object.keys(state.marketingChannels).length > 0) {
      return state.marketingChannels;
    }

    return null;
  },

  /**
   * Gets all the user details
   * @param state - the state object for userInfo
   * @param getters - the getter's object for userInfo
   */
  // @ts-expect-error - Issue with the way you call getters
  getAllDetails: (state: State, getters: Getters) => ({
    email: getters.getEmail,
    firstName: getters.getFirstName,
    lastName: getters.getLastName,
    phoneNumber: getters.getPhoneNumbers,
    postalCode: getters.getPostalCode,
    vehicleDetails: getters.getVehicleDetails
  }),
};

const mutations = {
  /**
   * Sets the user's email address
   * @param state - the state object for userInfo
   * @param payload
   * @param payload.email - The customer first name
   */
  commitEmail: (state: State, payload: { email: string }) => {
    state.email = payload.email;
  },

 /**
   * Sets the user's first name
   * @param state - the state object for userInfo
   * @param payload
   * @param payload.firstName - The customer first name
   */
  commitFirstName: (state: State, payload: { firstName: string }) => {
    state.firstName = payload.firstName;
  },

  /**
   * Sets the user's last name
   * @param state - the state object for userInfo
   * @param payload
   * @param payload.lastName - The customers last name
   */
  commitLastName: (state: State, payload: { lastName: string }) => {
    state.lastName = payload.lastName;
  },

  /**
   * Sets the user's phone number
   * @param state - the state object for userInfo
   * @param payload
   * @param payload.numbers - The different formats of the customer phone number
   */
  commitPhoneNumber: (state: State, payload: {
    phone_number: string,
    formatted_number: string,
    country_code: string
    country_iso_code: string,
  }) => {
    state.phoneDetails.phoneNumber = payload.phone_number ?? '';
    state.phoneDetails.phoneNumberWithDialingCode = payload.formatted_number ?? '';
    state.phoneDetails.dialingCode = payload.country_code ?? '';
    state.phoneDetails.isoCode = payload.country_iso_code ?? '';
  },

  /**
   * Sets the user's vehicle details
   * @param state - The state object for userInfo
   * @param payload - The payload to attach options
   * @param payload.postalCode - The customer zip/post code
   */
  commitPostalCode: (state: State, payload: { postalCode: string }) => {
    state.postalCode = payload.postalCode;
  },

  /**
   * Sets the user's vehicle details
   * @param state - the state object for userInfo
   * @param payload
   * @param payload.vehicleDetails - A list of the customer vehicle details
   */
  commitVehicleDetails: (state: State, payload: { vehicleDetails: VehiclePayload }) => {
    const {
      registrationNumber,
      make,
      colour,
      model
    } = payload.vehicleDetails;

    state.vehicleDetails.registrationNumber = registrationNumber;
    state.vehicleDetails.make = make ?? '';
    state.vehicleDetails.colour = colour ?? '';
    state.vehicleDetails.model = model ?? '';
  },

  /**
   * Sets the user's marketing settings details
   * @param state - the state object for userInfo
   * @param payload
   * @param payload.marketingChannels - Marketing preferences object
   */
  commitMarketingPreferences: (state: State, payload: { marketingChannels: State['marketingChannels'] }) => {
    state.marketingChannels = payload.marketingChannels;
  }
};

const actions = {
  /**
   * Get the user details from the API
   */
  getUserDetails: ({ commit, state }: UserInfoContext, bearerToken: string) => userDetails.get(bearerToken)
    .then((response) => {
      const {
        email,
        first_name,
        last_name,
        phone,
        zipcode,
        vehicles,
        channel_id,
        marketing_channels,
      } = response.body.data as UserDetailsResponse;

      commit('commitEmail', { email: email });
      commit('commitFirstName', { firstName: first_name });
      commit('commitLastName', { lastName: last_name });
      commit('commitPhoneNumber', { ...phone });
      commit('commitVehicleDetails', {
        vehicleDetails: {
          registrationNumber: vehicles?.[0].registration_number,
          make: vehicles?.[0].make,
          model: vehicles?.[0].model,
          colour: vehicles?.[0].colour,
        }
      });
      commit('commitPostalCode', { postalCode: zipcode ?? '' });
      commit('commitMarketingPreferences', { marketingChannels: marketing_channels });

      return {
        channel_id,
      };
    })
    .catch((error: any) => {
      throw new Error(error);
    }),
  patchUserDetails: ({ commit, state }: UserInfoContext, { payload, bearerToken }: { payload: UserPayload, bearerToken: string }) =>
    userDetails.update(payload, bearerToken)
      .then((response) => {
        commit('commitEmail', { email: payload.email });
        commit('commitFirstName', { firstName: payload.first_name });
        commit('commitLastName', { lastName: payload.last_name });
        commit('commitPhoneNumber', {
          phone_number: payload.phone.phone_number,
          formatted_number: payload.phone.formatted_number,
          country_code: payload.phone.country_code,
          country_iso_code: payload.phone.country_iso_code,
        });
        commit('commitVehicleDetails', {
          vehicleDetails: {
            registrationNumber: payload.vehicles?.[0].registration_number,
            make: payload.vehicles?.[0].make,
            model: payload.vehicles?.[0].model,
            colour: payload.vehicles?.[0].colour,
          }
        });
        commit('commitPostalCode', { postalCode: payload.zipcode });
        commit('commitMarketingPreferences', { marketingChannels: payload.marketing_channels });
        return response.status;
      })
      .catch((error: any) => {
        throw new Error(error);
      }),

  /**
   * Get the Auth0 change password redirect URL from the API
   */
  changeUserPassword: ({ commit }: UserInfoContext, bearerToken: string) =>
    userDetails.updatePassword(bearerToken)
      .then((response) => {
        const responseBody: ChangePasswordResponse = response.body;
        return responseBody.data.ticket;
      })
      .catch((error: any) => {
        throw new Error(error);
      }),

  /**
   * Resend Verification Email
   */
  resendVerificationEmail: ({ commit }: UserInfoContext, { payload, bearerToken }: { payload: EmailVerifyPayload, bearerToken: string }) =>
    emailVerification.resendVerification(payload, bearerToken)
      .then((response: any) => {
        return response.status;
      })
      .catch((error: any) => {
        throw new Error(error);
      }),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

const { read, commit, dispatch } = getStoreAccessors<State, RootState>('userInfo')

// Getters
export const readPhoneNumbers = read(getters.getPhoneNumbers);
export const readUserDetails = read(getters.getAllDetails);
export const readEmail = read(getters.getEmail);
export const readFirstName = read(getters.getFirstName);
export const readLastName = read(getters.getLastName);
export const readPostalCode = read(getters.getPostalCode);
export const readVehicleDetails = read(getters.getVehicleDetails);
export const readMarketingPreferences = read(getters.getMarketingPreferences);

// Mutations
export const commitEmail = commit(mutations.commitEmail);
export const commitFirstName = commit(mutations.commitFirstName);
export const commitLastName = commit(mutations.commitLastName);
export const commitPhoneNumber = commit(mutations.commitPhoneNumber);
export const commitPostalCode = commit(mutations.commitPostalCode)
export const commitVehicleDetails = commit(mutations.commitVehicleDetails);
export const commitMarketingPreferences = commit(mutations.commitMarketingPreferences);

// Actions
export const fetchUserDetails = dispatch(actions.getUserDetails);
export const updateUserDetails = dispatch(actions.patchUserDetails);
export const changeUserPassword = dispatch(actions.changeUserPassword);
export const resendVerificationEmail = dispatch(actions.resendVerificationEmail);
